var jqAjaxBs = {
    modules : {}
};

jqAjaxBs.modules.autoComplete = ( function() {
    return {
        bscomp_autocomplete: function (callback) {
            $.ajax({
                url: '/bs/autocomplete/bs',
                type: 'GET',
                dataType: 'json',
                data: {
                    numBs: $('#number').val()
                },
                success: callback,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },
    }
})();



jqAjaxBs.modules.change = ( function() {
    return {
        client_autocomplete: function () {
            $.ajax({
                url: '/bs/autocomplete/findclient',
                type: 'GET',
                dataType: 'json',
                data: {
                    nom: $('#last_name').val(),
                    prenom: $('#first_name').val(),
                    zip_code: $('#zip_code').val()
                },
                success: function(response) {
                    if (response) {
                        if(confirm('Un client éxistant à été trouvé avec le même nom et date de naissance. ' +
                                'Voulez-vous le sélectionner')){
                            $('#first_name').val(response.first_name.toString());
                            $('#birth_date').val($.format.date(response.birth_date, "dd/MM/yyyy"));
                            $('#address').val(response.address.toString());
                            $('#additional_address').val(response.additional_address.toString());
                            $('#zip_code').val(response.zip_code.toString());
                            $('#city').val(response.city.toString());
                            $('#phone_1').val(response.phone_1.toString());
                            $('#phone_2').val(response.phone_2.toString());
                            $('#phone_3').val(response.phone_3.toString());
                            $('#email').val(response.email.toString());
                        }
                    }
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },
        bs_autoincrementBS: function () {
            $.ajax({
                url: '/bs/autoincrementbs',
                type: 'GET',
                dataType: 'json',
                data: {
                    operator: $('#operator_id').val()
                },
                success: function(response) {
                    $('#number').val(response);
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },
        bs_lotNumber: function () {
            $.ajax({
                url: '/bs/getLot',
                type: 'GET',
                dataType: 'json',
                data: {
                    idbs: $('#idbs').val(),
                    typeEdit: $('#typeEdit').val(),
                    operator: parseInt($('#operator_id').val())
                },
                success: function(response) {
                    $('input[name="lot_number"]').val(response);
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },
        FindBsByClient: function () {
            $.ajax({
                url: '/bs/bsclient',
                type: 'GET',
                dataType: 'json',
                data: {
                    nom: $('#last_name').val(),
                    prenom: $('#first_name').val(),
                    zip_code: $('#zip_code').val(),
                    city: $('#city').val(),
                    address: $('#address').val()
                },
                success: function(response) {
                    if (response) {
                        $('#ModalBSClient').find('div.modal-body p:first')
                            .html( 'Un Bs est présent pour ce client <br>' +
                                ' Bs : ' + response.number + '<br>' +
                                ' Opérateur : ' + response.operator.label + '<br>' +
                                ' Date de signature : ' + response.signature_date + '<br>' +
                                ' Commercial : ' + response.salesperson.first_name + ' ' + response.salesperson.last_name + '<br>' +
                                ' Statut : ' + response.status.label)
                            .end()
                            .modal('show');
                    }
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },
        contrat_boutique: function () {
            $.ajax({
                url: '/bs/contratboutique',
                type: 'GET',
                dataType: 'json',
                data: {
                    salesperson_id: $('#salesperson_id').val()
                },
                success: function(response) {
                    if(response){
                        $('#yes_boutique').prop("checked", true)
                    }
                    else{
                        $('#no_boutique').prop("checked", true)
                    }
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },
        zip_code: function () {
            $.ajax({
                url: '/bs/control',
                type: 'GET',
                dataType: 'json',
                data: {
                    type: 'zip_code',
                    zip_code: $('#zip_code').val(),
                    operator_id: $('#operator_id').val(),
                    contrat_id: $('#contrat_id').val()
                },
                success: function(response) {
                    if(response.valid){
                        $('#zip_code').removeClass("uk-form-danger")
                        $('#zip_code_HS').remove();
                    }
                    else{
                        $('#zip_code').addClass("uk-form-danger")
                        $('#zip_code_HS').remove();
                        $('#zip_code').parent().append('<small id="zip_code_HS" class="uk-text-danger" data-fv-for="zip_code" data-fv-result="INVALID" style="display: block;">Le code postal du client est Hors secteur</small>')
                    }
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },
    }
})();

jqAjaxBs.modules.keyup = ( function(){
    return {
        loadCompletude: function (id) {
            $.ajax({
                url: '/bs/completude/' + id,
                type: 'GET',
                success: function(response) {
                    if (response) {
                        window.location.href = response;
                    }
                },
                error: function(response) {
                    if (response) {
                        window.location.href = response;
                    }
                },
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true
            });
        },
    }
})();

function pad2(number) {

    return (number < 10 ? '0' : '') + number

}

function CompleteClient(){
    var fournisseur = parseInt($('#operator_id').val());
    if(fournisseur == 5 || fournisseur == 8 || fournisseur == 11){
        if($('#last_name').val() != "" &&
            $('#first_name').val() != "" &&
            $('#zip_code').val() != ""){
            jqAjaxBs.modules.change.client_autocomplete();
        }
    }
}


function FindBsByClient(){
    if($('#last_name').val() != "" &&
        $('#first_name').val() != "" &&
        $('#address').val() != "" &&
        $('#city').val() != "" &&
        $('#zip_code').val() != "" &&
        $('#typeEdit').val() == 'add_bs'){
        jqAjaxBs.modules.change.FindBsByClient();
    }
}

function showoperator(load){
    var fournisseur = parseInt($('#operator_id').val())
        ,rows = $('#frmEditBs .uk-form-row, #frmEditBs input, #frmEditBs label, #frmEditBs select, #frmEditBs div.only').show()
                .filter('.only1, .only2, .only3, .only4, .only5, .only8, .only9, .only10, .only11, .only12, .only13, .only14, .only15, .only16, .only18, .only19')
         ,rows2 = $('#frmEditBs .uk-form-row, #frmEditBs input, #frmEditBs label, #frmEditBs select').show()
                .filter('.missing9, .missing11, .missing12, .missing16')
        ,rows3 = $('#frmEditBs option').show().filter('.only1, .only2, .only3, .only4, .only5, .only8, .only9, .only10, .only11, .only12, .only13, .only14, .only15, .only16, .only18, .only19');

    rows.hide().filter('.only'+fournisseur).show();
    rows2.filter('.missing'+fournisseur).hide();
    rows3.hide().filter('.only'+fournisseur).show();

    $('.only1 select, .only2 select, .only3 select, .only4 select, .only5 select, ' +
      '.only8 select, .only9 select, .only10 select, .only11 select, .only12 select, .only13 select, .only16 select, .only18 select, .only19 select').prop("disabled", true);
    $('#frmEditBs .only'+fournisseur + ' select').removeAttr('disabled');

    //Listing statuts
    $("#status_id option[value='18']").hide();

    $typeEdit = $('#typeEdit').val();

    //Fournisseur == ENI
    if(fournisseur == 1){
        if(!load || $('#number').val() == ''){
            $('#number').val('PPD');
            // $("input[type=radio][name=contrat_id]").val([1]);
            $('#contrat_id').val('1');
        }

        $("#commande_id option").hide().filter("option[class='only-1']").show();
    }
    //Fournisseur == KIWATCH
    else if(fournisseur == 2 || fournisseur == 13){
        if(!load || $('#num_bs').val() == ''){
            $('#number').val('SPPALY');
            // $("input[type=radio][name=contrat_id]").val([4]);
            $('#contrat_id').val('4');
        }
    }
    //Fournisseur = ANTARGAZ
    else if(fournisseur == 3 || fournisseur == 7){
        if(!load || $('#number').val() == ''){
            $('#number').val('');
            // $("input[type=radio][name=contrat_id]").val([1]);
            $('#contrat_id').val('1');

            //Initialisation des composants
            $('input[type=radio][name=rib]').val([1]);
            $('input[type=radio][name=paiement_mode]').val([1]);
            $('#divRib').show();
            $('input[type=radio][name=abandon_withdrawal_period]').val([0]);
            $('input[type=radio][name=boiler]').val([0]);

            //statut Validé Web que pour les G&G
            //$("#status_id option[value='16']").show();
        }
    }
    //Fournisseur = SOWATT
    else if(fournisseur == 4){
        if(!load || $('#number').val() == '') {
            $('#number').val('');
            // $("input[type=radio][name=contrat_id]").val([1]);
            $('#contrat_id').val('1');
        }
    }
    //Fournisseur = PLANET ASSURANCE
    else if(fournisseur == 5){
        if(!load || $('#number').val() == '') {
            $('#number').val('');
            // $("input[type=radio][name=contrat_id]").val([8]);
            $('#contrat_id').val('8');
        }
    }
    //Kiwatch Call
    else if(fournisseur == 6 || fournisseur == 8 || fournisseur == 9){
        if($('#typeEdit').val() == 'add_bs'){
            jqAjaxBs.modules.change.bs_autoincrementBS();
        }
    }
    //Fournisseur = TVCABLESAT
    else if(fournisseur == 8){
        if(!load || $('#number').val() == '') {
            $('#number').val('');
            $('#contrat_id').val('9');
        }
    }
    //Fournisseur = SFR Fibre
    else if(fournisseur == 12){
        if((!load && $('#number').val() == '')) {
            $('#number').val('');
            $('#contrat_id').val('10');
        }

        $("#status_id option[value='18']").show();

        $("#commande_id option").hide().filter("option[class='only-12']").show();
    }
    else if(fournisseur == 14 && !load ){
        $('#number').val('');
    }
    else if(fournisseur == 15 && !load ){
        $('#number').val('PFO');
        // $("input[type=radio][name=contrat_id]").val([13]);
        $('#contrat_id').val('13');
    }
    else if(fournisseur == 16 && !load ){
        $('#number').val('');
        // $("input[type=radio][name=contrat_id]").val([17]);
        $('#contrat_id').val('1');
    }

    if($typeEdit == 'prospect_bs'){
        $('#number').val('');
    }


    showEnergy();

    //Mise à jour du Lot
    jqAjaxBs.modules.change.bs_lotNumber();
}

function showOtherOperatorGaz(){
    if ($('#in_operator_gaz_id').val() == 0) {
        $('.other_operator_gaz').show();
    }
    else {
        $('.other_operator_gaz').hide();
    }
}

function showOtherOperatorElec(){
    if ($('#in_operator_elec_id').val() == 0) {
        $('.other_operator_elec').show();
    }
    else {
        $('.other_operator_elec').hide();
    }
}

function showEnergy(){
    var fournisseur = parseInt($('#operator_id').val());
    var contrat = parseInt($("#contrat_id").val());

    if(fournisseur == 1 || fournisseur == 3 || fournisseur == 7 || fournisseur == 9){
        var energy = $('#energy_id').val()
            ,rows = $('#frmEditBs .uk-form-row, #frmEditBs .onlygaz, #frmEditBs .onlyelec, #frmEditBs .onlypropane').filter('.onlygaz, .onlyelec, .onlypropane');

        if(energy == "1"){
            rows.hide().filter('.onlygaz').show();

            $("#duree_contrat option").hide();
            for(var i = 1; i <= 3; i++) {
                $("#duree_contrat option[value='" + i + "']").show();
            }
        }
        else if(energy == "2"){
            rows.hide().filter('.onlyelec').show();
        }
        else if(energy == "3"){
            rows.hide().filter('.onlyelec, .onlygaz').show();
        }
        else if(energy == "4"){
            rows.hide().filter('.onlypropane').show();

            if(contrat == 1 || contrat == 3){
                $("#duree_contrat option").hide();
                for(var i = 3; i <= 5; i+= 2) {
                    $("#duree_contrat option[value='" + i + "']").show();
                }
            }
            else{
                $("#duree_contrat option").hide();
                for(var i = 3; i <= 9; i+= 3) {
                    $("#duree_contrat option[value='" + i + "']").show();
                }
            }
        }
        else{
            rows.hide();
        }

        //On masque les champs non voulue
        var rows3 = $('#frmEditBs .uk-form-row, #frmEditBs input, #frmEditBs label, #frmEditBs option')
            .filter('.missing1, .missing3, .missing7, .missing9, .missing12, .missing16');

        rows3.filter('.missing'+fournisseur).hide();

        showOtherOperatorGaz();
        showOtherOperatorElec();
        showENIPRO();
    }
}

function showENIPRO(){
    var fournisseur = parseInt($('#operator_id').val());
    var contrat = parseInt($("#contrat_id").val());
    var energy = $('#energy_id').val();
    var typeEdit = $('#typeEdit').val();

    var rows = $('#frmEditBs .onlypro').filter('.onlypro');
    var rowsNotPro = $('#frmEditBs .missingpro').filter('.missingpro');

    if(fournisseur == 1 && contrat == 2){
        rows.hide().not('.onlygaz, .onlyelec, .onlypropane').show();
        rowsNotPro.hide();

        if(energy == "1"){
            rows.filter('.onlygaz').show();
        }
        else if(energy == "2"){
            rows.filter('.onlyelec').show();
        }
        else if(energy == "3"){
            rows.filter('.onlygaz, .onlyelec').show();
        }
        else if(energy == "4") {
            rows.filter('.onlypropane').show();
        }

        $('input[type=radio][name=client_engage_gaz]').trigger('change');
    }
    else{
        rows.hide()
        rowsNotPro.show();
        if(typeEdit == "add_bs"){
            $('#signature_date').val($.format.date(moment.now(), "dd/MM/yyyy"));
        }
    }

    rowsNotPro.filter('.missing'+fournisseur).hide();

}

function SetDateEniPRO() {
    var client_engage_gaz = $('input[type=radio][name=client_engage_gaz]:checked').val();
    var client_engage_elec = $('input[type=radio][name=client_engage_elec]:checked').val();
    var date_fin_engage_gaz = moment($('#date_fin_engage_gaz').val(), 'DD/MM/YYYY');
    var date_fin_engage_elec = moment($('#date_fin_engage_elec').val(), 'DD/MM/YYYY');

    var sign_date = null;

    if ((client_engage_gaz == 0 || isNaN(client_engage_gaz)) &&
        (client_engage_elec == 0 || isNaN(client_engage_elec))){
        sign_date = moment().add(30, 'days').format('DD/MM/YYYY');
    }
    else{
        if(isNaN(date_fin_engage_gaz) && isNaN(date_fin_engage_elec)){
            return;
        }
        else if(isNaN(date_fin_engage_elec) || (date_fin_engage_gaz >= date_fin_engage_elec ) ){
            sign_date = date_fin_engage_gaz.add(1, 'days').format('DD/MM/YYYY');
        }
        else if(isNaN(date_fin_engage_gaz) || (date_fin_engage_elec > date_fin_engage_gaz)){
            sign_date = date_fin_engage_elec.add(1, 'days').format('DD/MM/YYYY');
        }

    }

    if(sign_date != null){
        // $('#signature_date').val($.format.date(sign_date, "dd/MM/yyyy"));
        $('#fourniture_date').val($.format.date(sign_date, "dd/MM/yyyy"));
    }

}

function showTarifElec(){
    if ($('input[type=radio][name=vendor_index2]').is(":checked") && ($('input[type=radio][name=vendor_index2]:checked').val() == 1)) {
        if($('#tarifelec_id').val() == 2){   //HC / HP
            $('.indexHCHP').show();
        }
        else{
            $('.indexHCHP').hide();
        }
    }
    else{
        $('.indexHCHP').hide();
    }
}

function showindexgaz(){
    // Initialisation affichage index commercial
    if ($('input[type=radio][name=vendor_index]').is(":checked") && ($('input[type=radio][name=vendor_index]:checked').val() == 1)) {
        $('#vendor_index_value').show();
    }
    else {
        $('#vendor_index_value').hide();
    }
}

function showindexelec(){
    // Initialisation affichage index commercial
    if ($('input[type=radio][name=vendor_index2]').is(":checked") && ($('input[type=radio][name=vendor_index2]:checked').val() == 1)) {
        $('#vendor_index_value2').show();
    }
    else {
        $('#vendor_index_value2').hide();
    }
    showTarifElec();
}

$(document).ready(function() {

    if($('#typeEdit').val() == "consult_bs"){
        $("#frmEditBs :input").prop("disabled", true);
        $(".save-button").hide();
    }

    //Mise à jour du Lot
    jqAjaxBs.modules.change.bs_lotNumber();

    //Initialisation de l'opérateur
    showoperator(true);
    $('#operator_id').change(function () {
        showoperator(false);
    });

    //Disable for Operator
    if($('#isOperator').val() == "1" && $('#isManager').val() == "1"){
        $("#frmEditBs :input, #frmEditBs select").prop("disabled", true);
        $("#btnSubmit").removeAttr('disabled');
        $("#btnSubmit, #status_id, #status_fixe_check, #status_fixe").removeAttr('disabled');
        $('input[name=_token]').removeAttr('disabled');
    }


    // Initialisation de l'energie
    showEnergy();
    $('#energy_id').change(function () {
        showEnergy();
    });

    //InitPro
    showENIPRO();

    // Initialisation affichage RIB
    if ($('input[type=radio][name=rib]').is(":checked") && ($('input[type=radio][name=rib]:checked').val() == 1)) {
        $('#divRib').show();
    }
    else {
        $('#divRib').hide();
    }

    // Initialisation autre opérateur antérieur
    showOtherOperatorGaz();
    $('#in_operator_gaz_id').change(function () {
        showOtherOperatorGaz();
    });
    showOtherOperatorElec();
    $('#in_operator_elec_id').change(function () {
        showOtherOperatorElec();
    });

    showTarifElec();
    $('#tarifelec_id').change(function(){
        showTarifElec();
    });

    $('input[type=radio][name=client_engage_gaz], input[type=radio][name=client_engage_elec], input[name=date_fin_engage_gaz], input[name=date_fin_engage_elec]').change(function(){

        $('.date_fin_engage_gaz').hide();
        $('.date_fin_engage_elec').hide();

        if($('input[type=radio][name=client_engage_gaz]:checked').val() == 1){
            $('.date_fin_engage_gaz').show();
        }
        if($('input[type=radio][name=client_engage_elec]:checked').val() == 1){
            $('.date_fin_engage_elec').show();
        }

        SetDateEniPRO();
    });

    $('input[name=date_fin_engage_gaz], input[name=date_fin_engage_elec]').on('change.daterangepicker', function(ev, picker) {
        SetDateEniPRO();
        $(this).trigger('focus.daterangepicker');
    });

    /* Affichage de la valeur de l'index commercial */
    showindexgaz();
    $('input[type=radio][name=vendor_index]').on('change', function() {
        showindexgaz();
    });

    showindexelec();
    /* Affichage de la valeur de l'index commercial */
    $('input[type=radio][name=vendor_index2]').on('change', function() {
        showindexelec();
    });

    /* AutoComplete */
    if ($('#typeEdit').val() == "complete_bs") {
        $('#number').autocomplete({
            source: function (request, response) {
                jqAjaxBs.modules.autoComplete.bscomp_autocomplete(function (data) {
                    response($.map(data, function (object) {
                        return object.number;
                    }));
                });
            }
            ,
            minLength: 2
        });
    }

    /* Masque de saisie IBAN */
    // $('#iban').mask("FR00 0000 0000 0000 0000 0000 000", {placeholder: "____ ____ ____ ____ ____ ____ ___"});

    /* Selection auto du type de contrat en fonction du préfix du N°BS */
    $('#number').keyup(function(e){
        if ($('#typeEdit').val() == "complete_bs") {
            if(($(this).val().match("[A-Z]{6}[0-9]{7}") != null) ||
               ($(this).val().match("[A-Z]{3}[0-9]{7}") != null) ||
                e.keyCode == 13)
            {
                jqAjaxBs.modules.keyup.loadCompletude($(this).val());
            }
        }
        else {
            //Choix du type de contrat en fonction du préfix
            if ($(this).val().length >= 3) {
                if ($(this).val().substr(0, 3) == "PPD") {
                    // $("input[type=radio][name=contrat_id]").val([1]);
                    $('#number').attr('maxlength','10');
                    $('#contrat_id').val('1');
                }
                else if ($(this).val().substr(0, 3) == "PPG") {
                    // $("input[type=radio][name=contrat_id]").val([3]);
                    $('#number').attr('maxlength','10');
                    $('#contrat_id').val('3');
                }
                else if ($(this).val().substr(0, 3) == "LTY") {
                    // $("input[type=radio][name=contrat_id]").val([1]);
                    $('#number').attr('maxlength', '13');
                    $('#contrat_id').val('3');
                }
                else if ($(this).val().substr(0, 5) == "DTD") {
                    // $("input[type=radio][name=contrat_id]").val([2]);
                    $('#number').attr('maxlength','10');
                    $('#contrat_id').val('2');
                }


                if ($(this).val().length >= 6) {
                    if ($(this).val().substr(0, 5) == "SPPALY") {
                        $('#number').attr('maxlength','13');
                    }
                }

                if ($('#operator_id').val() == 3) {
                        $('#number').attr('maxlength','5');
                }
            }
        }

    });

    $('input[type=radio][name=contrat_id]').on('change', function() {
        if (["1","2","6"].includes($('#operator_id').val())) {
            switch ($(this).val()) {
                case '1': //VAD
                    if ($('#number').val().length <= 6)
                        $('#number').val('PPD');

                    $('#number').attr('maxlength', '10');
                    $('#contrat_id').val('1');

                    break;
                case '2': // PRO
                    if ($('#number').val().length <= 6)
                        $('#number').val('DTD');

                    $('#number').attr('maxlength', '10');
                    $('#contrat_id').val('2');
                    break;
                case '3': // STAND
                    if ($('#number').val().length <= 6)
                        $('#number').val('PPG');

                    $('#number').attr('maxlength', '10');
                    $('#contrat_id').val('3');
                    break;
                case '4', '5': // KIWATCH
                    if ($('#number').val().length <= 6)
                        $('#number').val('SPPALY');

                    $('#number').attr('maxlength', '13');
                    break;
            }
        }
        showEnergy();
    });

    /* Affichage des champs pour la saisie du RIB en fonction si coché ou pas */
    $('input[type=radio][name=rib]').on('change', function() {
        switch ($(this).val()) {
            case '1':
                $('#divRib').show();
                /* code mode paiement Prélèvement */
                $('input[type=radio][name=paiement_mode]').val([1]);
                break;
            case '0':
                $('#divRib').hide();
                /* code mode paiement Chèque */
                $('input[type=radio][name=paiement_mode]').val([2]);
                break;
        }
    });

    $('input[type=radio][name=type_techno]').on('change', function() {

        if($('#typeEdit').val() == 'add_bs')
        {
            var date = new Date();
            var numberDate = date.getFullYear().toString() + pad2(date.getMonth() + 1).toString() + pad2(date.getDate()).toString();
            var commande = $('#commande_id').val();
            var fournisseur = parseInt($('#operator_id').val());

            $('.box_v8').hide();

            if(fournisseur == 12 && commande != '3' && commande != '4'){
                switch ($(this).val()) {
                    //THD ou CBL + année+mois+jour
                    case 'FTTB':
                        $('#number').val('CBL' + numberDate);
                        break;
                    case 'FTTH':
                        $('#number').val('THD' + numberDate);
                        //Box v8 visible
                        $('.box_v8').show();
                        break;
                }
            }
        }
    });

    $('#commande_id').change(function(){
        var fournisseur = parseInt($('#operator_id').val());

        if(fournisseur == 12)
        {
            var date = new Date();
            var numberDate = date.getFullYear().toString() + pad2(date.getMonth() + 1).toString() + pad2(date.getDate()).toString();

            $("input[type=radio][name=contrat_id]").hide().filter('.only12').show();


            $('.type_techno').addClass('be-required');
            $('.commande').hide();
            $('.box_v8').hide();

            //commande_id
            if($('#typeEdit').val() == 'add_bs'){
                switch ($(this).val()) {
                    //VLA + année+mois+jour
                    case '3':
                        $('#number').val('VLA' + numberDate);
                        $('.commande3').show();
                        $('#contrat_id').val(19);
                        break;
                    case '4':
                        $('#number').val('PRX' + numberDate);
                        $('.commande1, .commande2, .commande4').show();
                        $('.type_techno').removeClass('be-required');
                        break;
                    case '9': //SFR Securité
                        $('#number').val('SECU' + numberDate);
                        $('.commande9').show();
                        $('#contrat_id').val(25);
                        $('.type_techno').removeClass('be-required');
                        break;
                    default:
                        $('#contrat_id').val(10);
                        $('.box_v8').show();
                        $('.commande1, .commande2').show();
                        $('input[type=radio][name=type_techno]').change();
                        break;
                }
            }
            else{
                switch ($(this).val()) {
                    case '3':
                        $('.commande3').show();
                        break;
                    case '4':
                        $('.commande1, .commande2, .commande4').show();
                        $('.type_techno').removeClass('be-required');
                        break;
                    case '9': //SFR Securité
                        $('.commande9').show();
                        $('.type_techno').removeClass('be-required');
                        break;
                    default:
                        $('.box_v8').show();
                        $('.commande1, .commande2').show();
                        $('input[type=radio][name=type_techno]').change();
                        break;
                }
            }
        }
        else if(fournisseur == 1){
            //Commande Maestro => coche energie verte et chaudière
            if($(this).val() == 6){
                $('#yes_green_energy').prop("checked", true);
                $('#yes_boiler').prop("checked", true);
            }
        }
    });
    $('#commande_id').trigger('change');

    $('input[type=radio][name=deja_client]').on('change', function() {
        if($(this).val() == '1'){
            $('#in_operator_gaz_id').val('6');
            $('#in_operator_elec_id').val('6');
        }
    });

    $('.compteur_linky').hide();
    $('input[type=radio][name=facturation_mode]').on('change', function() {
        $('.compteur_linky').hide();

        if($(this).val() == '1'){
            $('.compteur_linky').show();
        }
    });

    $('input[type=checkbox][name=status_fixe_check]').on('change', function() {
        if($(this)[0].checked){
            $('#status_fixe').val(1);
        }
        else{
            $('#status_fixe').val(0);
        }
    });
    $('input[type=checkbox][name=status_fixe_check]').trigger('change');

    $('input[type=checkbox][name=fixe_vendeur_check]').on('change', function() {
        if($(this)[0].checked){
            console.log(1);
            $('#fixe_vendeur').val(1);
        }
        else{
            console.log(0);
            $('#fixe_vendeur').val(0);
        }
    });

    $('#last_name, #first_name, #address, #city, #zip_code').on('change', function() {
        CompleteClient();
        FindBsByClient();
    });


    $(".BtnConfModal").click(function(){
        $('#frmEditBs').data('formValidation').validate();
        if($('#frmEditBs').data('formValidation').isValid()){
            $('#ModalBSSave').modal('show');
        }
    });

    // Hide the Modal
    $("#ModalBSSaveClose").click(function(){
        $("#ModalBSSave").modal("hide");
    });

    // Load Field BS number / code navy / signature date
    $("#btn_generer_bs").click(function(e){
        e.preventDefault();
        e.stopPropagation();
        $(".row-prospect").removeClass('uk-hidden');
        $(".row-prospect").show();

        $("#status_id").val('2');
    });

    $('#salesperson_id').change(function(e){
        jqAjaxBs.modules.change.contrat_boutique();
    });

    $('#zip_code').change(function(e){
        jqAjaxBs.modules.change.zip_code();
    });





    $('.BtnDeleteUnavailability').on("click", function() {
        // Obtenez l'ID de la ligne à partir de l'attribut de données
        var unavailableId = $(this).data('unavailable-id');

        // Mettez à jour le lien "Oui" de la modal avec l'ID correspondant
        var deleteLink = $("#BtnModalDeleteUnavailability");
        deleteLink.attr('href', "/bs/prospections_rdv/rdv_unavailability/delete/" + unavailableId); // Remplacez par l'URL de votre route de suppression

        // Affichez la modal
        $('#ModalDeleteUnavailability').show();
    })




});


